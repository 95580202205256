.justify-content-center {
    display: flex;
    justify-content: center;
  }

.button {
    box-shadow: none;
    height: 31px;
    color: #FFFFFF !important;
    margin: auto;
    background: #1EB980 !important;
    border-radius: 2px;
    font-size: 13px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: bold;
}

.button:hover {
  background-color: #1EB980;
  box-shadow: none; 
}

.loading{
  width: 40px;
  height: 40px;
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}